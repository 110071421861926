import React, { useContext, useState, useEffect } from 'react'
import NSearch from '../components/functions/Search'
import { Link } from 'react-router-dom'
import ClusterCard from '../components/functions/ClusterCard'
import { WordpressContext } from '../context/Wordpress'
import GAScrolLEvent from "../components/functions/GAScrollEvent";
import {motion} from "framer-motion";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

function Search() {
    const [cluster, setCluster] = useState([])
    const wordpressContext = useContext(WordpressContext)
    useEffect(() => {
        setCluster(wordpressContext.getClusters())
    }, [wordpressContext])

    ReactGA.send({ hitType: "pageview", page: "/search" });
    return (
        <motion.div className={"App"} initial={{opacity:0, x:100}} animate={{opacity:1, x:0}} exit={{opacity:0, x:-100}} transition={{duration: 0.2}}>
            <Helmet>
                <title>Career Clusters from Ball State University</title>
                <meta name="description" content="Browse career clusters as defined by the Indiana Department of Education." />
            </Helmet>
            <div className="search-page">
                <div className="container">
                    <NSearch />
                </div>
                <div className="clusters">
                    <h3>Career Clusters</h3>
                    <div className="container" onScroll={GAScrolLEvent}>
                        {cluster.map((data) => (
                            <Link to={'/cluster/' + data.term_id}>
                                <ClusterCard
                                    name={data.name}
                                    image={data.image}
                                    key={data.term_id}
                                />
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Search
