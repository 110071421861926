import ReactGA from "react-ga4";

export default function GAScrolLEvent() {
    let hasScrolled = false;
    if (!hasScrolled) {
        ReactGA.event({
            category: "Career Cluster Browse",
            action: "Swipe",
            label: "Cluster Swipe",
            transport: "xhr",
        });
        hasScrolled = true
    }
}