import { Link } from 'react-router-dom'
import arrow from '../../assets/images/courseArrow.svg'
import '../../assets/css/CourseList.css'
import React from "react";

export default function CourseList(props) {
    let gray = false

    return (
        <div className="CourseList">
            <ul>
                {props.classes.map((class_) => {
                    gray = !gray
                    return (
                        <Link
                            to={'/course/' + class_.ID}
                            state={props.prevURLs}
                        >
                            <li
                                key={class_.post_title}
                                className={gray ? 'gray' : ''}
                            >
                                <p><b><i>{class_.post_title}</i></b> {class_.course_name}</p>
                                <img src={arrow} alt="" />
                            </li>
                        </Link>
                    )
                })}
            </ul>
        </div>
    )
}
