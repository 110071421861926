import '../../assets/css/TransferabilityCard.css'

export default function TransferabilityCard(props) {
    return (
        <div className="TransferabilityCard">
            <h3>{props.title}</h3>

            {props.description && (
                <p className="description">{props.description}</p>
            )}
        </div>
    )
}
