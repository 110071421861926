import React, { useState } from 'react'

const useWordpresshData = () => {
    const [clusters, setClusters] = useState([])
    const [pathways, setPathways] = useState([])
    const [majors, setMajors] = useState([])
    const [courses, setCourses] = useState([])
    const [coreCourses, setCoreCourses] = useState([])

    const getCluster = (id) => {
        for (var cluster of clusters) {
            if (cluster.term_id == id) {
                return cluster
            }
        }
        return undefined
    }

    const getClusters = () => {
        return clusters
    }

    const getPathway = (id) => {
        for (var pathway of pathways) {
            if (pathway.term_id == id) {
                return pathway
            }
        }
        return undefined
    }

    const getPathwaysByCluster = (id) => {
        var tempPathways = []
        for (var pathway of pathways) {
            if(pathway.parent == id && pathway.count!=0) {
                tempPathways.push(pathway)
            }
        }
        return tempPathways
    }

    const doesClusterHavePathways = (id) => {
        var tempPathways = []
        for (var pathway of pathways) {
            if(pathway.parent == id && pathway.count!=0) {
                tempPathways.push(pathway)
            }
        }
        return tempPathways.length!=0
    }

    const getMajors = () => {
        return majors
    }

    const getMajor = (id) => {
        for (var major of majors) {
            if (major.ID == id) {
                return major
            }
        }
        return undefined
    }

    const getMajorsByPathway = (id) => {
        var tempMajors = []
        for (var major of majors) {
            major.clusters.includes(parseInt(id)) && tempMajors.push(major)
        }
        return tempMajors
    }

    const getMajorNamesByPathway = (id) => {
        var tempMajorNames = []
        for (var major of majors) {
            major.clusters.includes(parseInt(id)) &&
                tempMajorNames.push(major.post_title)
        }
        return tempMajorNames
    }

    const getCourses = () => {
        return courses
    }

    const getCourse = (id) => {
        for (var course of courses) {
            if (course.ID == id) {
                return course
            }
        }
        return undefined
    }

    const getCoursesByMajor = (id) => {
        var tempCourses = []
        var major = getMajor(id)
        for (var courseId of major.courses) {
            var course = getCourse(courseId)
            tempCourses.push(course)
        }
        return tempCourses
    }

    const getCoreCourses = (ids) => {
        return coreCourses
    }

    const storeInfo = (data) => {
        var tempClusters = []
        var tempPathways = []
        var tempCoreCourses = []
        var tempFullCluster = []
        var doesHavePathways = false;

        for (var cluster of data.clusters) {
            if (cluster.parent == 0) {
                tempClusters.push(cluster)
            } else if(cluster.parent!=0) {
                tempPathways.push(cluster)
            }
        }

        for (var courseId of data.core) {
            for (var course of data.courses) {
                if (course.ID == courseId) {
                    tempCoreCourses.push(course)
                }
            }
        }

        for(var cluster of tempClusters) {
            doesHavePathways = false
            for(var pathway of tempPathways)
                if(pathway.parent == cluster.term_id && pathway.count!=0){
                    doesHavePathways = true;
                }
            if(doesHavePathways){
                tempFullCluster.push(cluster)
            }
        }

        setClusters(tempFullCluster)
        setPathways(tempPathways)
        setMajors(data.majors)
        setCourses(data.courses)
        setCoreCourses(tempCoreCourses)

    }

    return {
        getCluster,
        getClusters,
        getPathway,
        getMajors,
        doesClusterHavePathways,
        getMajor,
        getPathwaysByCluster,
        getMajorsByPathway,
        getMajorNamesByPathway,
        getCourses,
        getCourse,
        getCoursesByMajor,
        getCoreCourses,
        storeInfo,
    }
}

export default useWordpresshData

export const WordpressContext = React.createContext({})

export const WordpressContextProvider = (props) => {
    return (
        <WordpressContext.Provider value={useWordpresshData()}>
            {props.children}
        </WordpressContext.Provider>
    )
}
