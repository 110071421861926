import React from 'react'
import '../../assets/css/MajorButton.css'

export default function MajorButton(props) {
    return (
        <div className={'major-button-div'}>
        <a className={'major-button-text'} href={props.link} target="_blank">
            Visit Major
        </a>
        </div>
    )
}
