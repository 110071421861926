import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useParams, useLocation } from 'react-router-dom'
import { WordpressContext } from '../context/Wordpress'
import Header from '../components/functions/Header'
import TransferabilityCardWithMeter from '../components/functions/TransferabilityCardWithMeter'
import meterHigh from '../assets/images/Meter-High.svg'
import meterLow from '../assets/images/Meter-Low.svg'
import meterMedium from '../assets/images/Meter-Medium.svg'
import TransferabilityCard from '../components/functions/TransferabilityCard'
import CourseCatalogButton from '../components/functions/CourseCatalogButton'
import ClusterNav from '../components/functions/ClusterNav'
import {motion} from "framer-motion";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

let lorem =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'

function Course() {
    const [course, setCourse] = useState({})
    const wordpressContext = useContext(WordpressContext)
    const { id } = useParams()
    const location = useLocation()
    var prevURLs = location.state ? location.state : []


    useEffect(() => {
        setCourse(wordpressContext.getCourse(id))
    }, [id])
    ReactGA.send({ hitType: "pageview", page: `/course/${course.post_title}` });

    const clickEvent = () => {
        ReactGA.event({
            category: "Major BSU Link",
            action: "Click",
            label: `${course.post_title}`,
            transport: "xhr",
        });
    }
    return course !== undefined ? (
        <>
            <motion.div initial={{opacity:0, x:100}} animate={{opacity:1, x:0}} exit={{opacity:0, x: -100}} transition={{duration: 0.3}}>
                <Helmet>
                    <title>{`${course.post_title} - ${course.course_name} from Ball State University`}</title>
                    <meta name="description" content={course.post_content} />
                </Helmet>
                <Header
                    type="Course"
                    title={course.post_title + ' - ' + course.course_name}
                    link={'/major/' + course.ID}
                    description={course.post_content}
                />
                <TransferabilityCardWithMeter
                    title={'Public Universities In-State'}
                    description={MeterCheckDescription(course.in_state_public, "public")}
                    image={MeterCheck(course.in_state_public)}
                    alt={MeterCheckAlt(course.in_state_public)}
                />
                <TransferabilityCardWithMeter
                    title={'Private Universities In-State'}
                    description={MeterCheckDescription(course.in_state_private, "private")}
                    image={MeterCheck(course.in_state_private)}
                    alt={MeterCheckAlt(course.in_state_private)}
                />
                <TransferabilityCard
                    title={'Public Universities Out-of-State'}
                    description={'The transferability of this course to public out-of-state universities is uncertain. Please contact your university for confirmation of transferability before registering.'}
                />
                <TransferabilityCard
                    title={'Private Universities Out-of-State'}
                    description={'The transferability of this course to private out-of-state universities is uncertain. Please contact your university for confirmation of transferability before registering.'}
                />
                <CourseCatalogButton
                    link={'https://catalog.bsu.edu/current/Undergraduate-Catalog'}
                    onClick={clickEvent}
                />
            </motion.div>
            <ClusterNav
                title={course.post_title}
                prevURLs={prevURLs.concat([course.post_title])}
            />
        </>
    ) : (
        <Navigate to="/" />
    )
}

function MeterCheck(meterStatus) {
    if (meterStatus === 'high') {
        return meterHigh
    }
    if (meterStatus === 'medium') {
        return meterMedium
    } else {
        return meterLow
    }
}

function MeterCheckAlt(meterStatus){
    if (meterStatus === 'high') {
        return 'High Transfer Probable'
    }
    if (meterStatus === 'medium') {
        return 'Medium Transfer Probable'
    } else {
        return 'Low Transfer Probable'
    }
}

function MeterCheckDescription(meterStatus, schoolType){
    if(meterStatus === 'high'){
        return `This course will likely transfer to any ${schoolType} in-state universities. Please contact your university for confirmation of transferability before registering.`
    }
    else if (meterStatus === 'medium'){
        return `This course may transfer to ${schoolType} in-state universities. Please contact your university for confirmation of transferability before registering.`
    }
    else {
        return `The transferability of this course to ${schoolType} in-state universities is uncertain. Please contact your university for confirmation of transferability before registering.`
    }
}

export default Course
