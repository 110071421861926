import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useParams, useLocation } from 'react-router-dom'
import { WordpressContext } from '../context/Wordpress'
import Header from '../components/functions/Header'
import CourseList from '../components/functions/CourseList'
import ClusterNav from '../components/functions/ClusterNav'
import '../assets/css/CardList.css'
import MajorClassCheck from "../components/functions/MajorClassCheck";
import MajorButton from '../components/functions/MajorButton'
import {motion} from "framer-motion";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

function Major() {
    const [major, setMajor] = useState([])
    const [courses, setCourses] = useState([])
    const [coreCourses, setCoreCourses] = useState([])
    const wordpressContext = useContext(WordpressContext)
    const { id } = useParams()
    const location = useLocation()
    var prevURLs = location.state ? location.state : []

    useEffect(() => {
        setMajor(wordpressContext.getMajor(id))
        setCourses(wordpressContext.getCoursesByMajor(id))
        setCoreCourses(wordpressContext.getCoreCourses(id))
    }, [id])
    const clickEvent = () => {
        ReactGA.event({
            category: "Major BSU Link",
            action: "Click",
            label: `${major.post_title}`,
            transport: "xhr",
        });
    }


    ReactGA.send({ hitType: "pageview", page: `/major/${major.post_title}` });

    return major !== undefined && courses !== undefined ? (
        <motion.div initial={{opacity:0, x:100}} animate={{opacity:1, x:0}} exit={{opacity:0, x: -100}} transition={{duration: 0.3}}>
            <Helmet>
                <title>{`${major.post_title} from Ball State University`}</title>
                <meta name="description" content={major.post_content} />
            </Helmet>
            <Header
                type="Major"
                title={major.post_title}
                description={major.post_content}
                program={major.department}
                credits={major.total_credits}
                occupations={major.occupations}
            />
            <div className="CardList">
                <h3 className="classHeader">Core Classes</h3>
                <CourseList
                    prevURLs={prevURLs.concat([major.post_title])}
                    classes={coreCourses}
                />
                <MajorClassCheck courses={courses} prevURLs={prevURLs} major={major}/>
            </div>
            <MajorButton link={major.link} onClick={clickEvent}/>
            <ClusterNav
                title={major.post_title}
                prevURLs={prevURLs.concat([major.post_title])}
            />
        </motion.div>
    ) : (
        <Navigate to="/" />
    )
}

export default Major
