import React, { useEffect, useState, useContext } from 'react'
import { WordpressContext } from '../../context/Wordpress'
import CourseItem from './CourseItem'
import MajorItem from './MajorItem'
import '../../assets/css/Search.css'
import searchIcon from '../../assets/images/search-icon.svg'

import cap from '../../assets/images/cap.svg'
import book from '../../assets/images/textbook.svg'
import ReactGA from "react-ga4";

export default function NewSearch() {
    const [courses, setCourses] = useState([])
    const [majors, setMajors] = useState([])
    const [searchText, setSearchText] = useState('')

    const wordpressContext = useContext(WordpressContext)

    useEffect(() => {
        setCourses(wordpressContext.getCourses())
        setMajors(wordpressContext.getMajors())
    }, [wordpressContext])

    const filterCourse = courses.filter(
        (course) =>
            (course.post_title
                .toLowerCase()
                .includes(searchText.toLowerCase()) || !searchText) || (course.course_name
                .toLowerCase()
                .includes(searchText.toLowerCase()) || !searchText)
    )

    const filterMajor = majors.filter(
        (major) =>
            major.post_title.toLowerCase().includes(searchText.toLowerCase()) ||
            !searchText
    )
    const clickEvent = () => {
        ReactGA.event({
            category: "Search bar",
            action: "Navigate",
            label: 'Search',
            transport: "xhr",
        });
        ReactGA.event({
            category: "Search bar",
            action: "Type",
            label: `${searchText}`,
            transport: "xhr",
        });
    }
    return (
        <div className="search-bar">
            <div className="container">
                <h1>Search</h1>
                <div className="search-container">
                    <input
                        placeholder="Search by course or major"
                        onChange={(event) => setSearchText(event.target.value)}
                    />

                    <div className="dropdown">
                        <div className="container" onClick={clickEvent}>
                            {filterCourse.length > 0 && (
                                filterCourse.map((course) => {
                                    return (
                                        <div className="item">
                                            <img src={book} alt="" />
                                            <CourseItem
                                                key={course.ID}
                                                course={course}
                                            />
                                        </div>
                                    )
                                })
                            )}
                            {filterMajor.length > 0 && (
                                filterMajor.map((major) => {
                                    return (
                                        <div className="item">
                                            <img src={cap} alt="" />
                                            <MajorItem
                                                key={major.ID}
                                                major={major}
                                            />
                                        </div>
                                    )
                                })
                            )}

                            {filterMajor.length === 0 && filterCourse.length === 0 && <p className={"no-results"}>No results found</p>}

                        </div>
                    </div>
                    <img className={"search-icon"} src={searchIcon} />
                </div>
            </div>
        </div>
    )
}
