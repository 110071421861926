import React from 'react'
import { Link } from 'react-router-dom'

function MajorItem(props) {
    const { ID, post_title } = props.major
    return (
        <div key={props.key}>
            <Link to={'/major/' + ID}>
                <p>{post_title}</p>
            </Link>
        </div>
    )
}

export default MajorItem
