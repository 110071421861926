import React, { useContext, useState, useEffect } from 'react'
import { Navigate, useParams, useLocation } from 'react-router-dom'
import { WordpressContext } from '../context/Wordpress'
import Header from '../components/functions/Header'
import ClusterNav from '../components/functions/ClusterNav'
import WhiteCard from '../components/functions/WhiteCard'
import '../assets/css/CardList.css'
import {motion} from "framer-motion";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

function Pathway() {
    const [pathway, setPathway] = useState({})
    const [majors, setMajors] = useState([])
    const wordpressContext = useContext(WordpressContext)
    const { id } = useParams()
    const location = useLocation()
    var prevURLs = location.state ? location.state : []


    useEffect(() => {
        setPathway(wordpressContext.getPathway(id))
        setMajors(wordpressContext.getMajorsByPathway(id))
    }, [id])

    ReactGA.send({ hitType: "pageview", page: `/pathway/${pathway.name}` });

    return pathway !== undefined ? (
        <motion.div initial={{opacity:0, x:100}} animate={{opacity:1, x:0}} exit={{opacity:0, x: -100}} transition={{duration: 0.3}}>
            <Helmet>
                <title>{`${pathway.name} from Ball State University`}</title>
                <meta name="description" content={`${pathway.description}`} />
            </Helmet>
            <Header
                type="Career Pathway"
                title={pathway.name}
                description={pathway.description}
            />
            <ul className="CardList">
                {majors.map((major) => (
                    <WhiteCard
                        title={major.post_title}
                        description={major.post_content}
                        prevURLs={prevURLs.concat([pathway.name])}
                        link={'/major/' + major.ID}
                    />
                ))}
            </ul>
            <ClusterNav
                title={pathway.name}
                prevURLs={prevURLs.concat([pathway.name])}
            />
        </motion.div>
    ) : (
        <Navigate to="/" />
    )
}

export default Pathway
