import React from 'react'
import SpriteStudent from '../../assets/images/spr_student.svg'
import SpriteParent from '../../assets/images/spr_parent.svg'
import SpriteCouncelor from '../../assets/images/spr_counselor.svg'
import '../../assets/css/CallingCard.css'

export default function CallingCard(props) {
    if (props.id === 1) {
        return (
            <div className="calling-card">
                <div id="text">
                    <div id="crop">
                        <p>I am a...</p>
                        <h3>Counselor</h3>
                    </div>
                </div>
                <div id="sprite">
                    <div id="crop">
                        <img src={SpriteCouncelor} alt="" />
                    </div>
                </div>
            </div>
        )
    } else if (props.id === 2) {
        return (
            <div className="calling-card">
                <div id="text">
                    <div id="crop">
                        <p>I am a...</p>
                        <h3>Student</h3>
                    </div>
                </div>
                <div id="sprite">
                    <div id="crop">
                        <img src={SpriteStudent} alt="" />
                    </div>
                </div>
            </div>
        )
    } else if (props.id === 3) {
        return (
            <div className="calling-card">
                <div id="text">
                    <div id="crop">
                        <p>I am a...</p>
                        <h3>Parent</h3>
                    </div>
                </div>
                <div id="sprite">
                    <div id="crop">
                        <img src={SpriteParent} alt="" />
                    </div>
                </div>
            </div>
        )
    }
}
