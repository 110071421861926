import './App.css'
import Header from './components/important/Header'
import Footer from './components/important/Footer'
import { BrowserRouter } from 'react-router-dom'
import AnimatedRoutes from "./components/functions/AnimatedRoutes";
import ReactGA from "react-ga4";

function App() {
    ReactGA.initialize([
        {
            trackingId: "G-1F44YXCVRS",
        },
    ]);
    return (
        <BrowserRouter>
            <Header />
            <AnimatedRoutes/>
            <Footer />
        </BrowserRouter>
    )
}

export default App
