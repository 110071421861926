import '../../assets/css/TransferabilityCard.css'

export default function TransferabilityCardWithMeter(props) {
    return (
        <div className="TransferabilityCardWithMeter">
            <div className={'Text-Info-Div'}>
                <h3>{props.title}</h3>

                {props.description && (
                    <p className="description">{props.description}</p>
                )}
            </div>
            <div className={'Meter-Div'}>
                <img className={'transfer-meter'} src={props.image} alt={props.alt}/>
            </div>
        </div>
    )
}
