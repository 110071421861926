import React, { useContext, useState, useEffect } from 'react'
import { Navigate, useParams, useLocation } from 'react-router-dom'
import { WordpressContext } from '../context/Wordpress'
import Header from '../components/functions/Header'
import ClusterNav from '../components/functions/ClusterNav'
import WhiteCard from '../components/functions/WhiteCard'
import '../assets/css/CardList.css'
import {motion} from "framer-motion";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

function Cluster() {
    const [cluster, setCluster] = useState({})
    const [pathways, setPathways] = useState([])
    const wordpressContext = useContext(WordpressContext)
    const { id } = useParams()
    const location = useLocation()
    var prevURLs = location.state ? location.state : []


    useEffect(() => {
        setCluster(wordpressContext.getCluster(id))
        setPathways(wordpressContext.getPathwaysByCluster(id))
    }, [id])

    ReactGA.send({ hitType: "pageview", page: `/cluster/${cluster.name}` });

    return cluster !== undefined ? (
        <motion.div initial={{opacity:0, x:100}} animate={{opacity:1, x:0}} exit={{opacity:0, x: -100}} transition={{duration: 0.3}}>
            <Helmet>
                <title>{`${cluster.name} from Ball State University`}</title>
                <meta name="description" content={cluster.description} />
            </Helmet>
            <Header
                type="CAREER CLUSTER"
                title={cluster.name}
                description={cluster.description}
                image={cluster.image}
            />
            <ul className="CardList">
                {pathways.map((pathway) => (
                    <WhiteCard
                        title={pathway.name}
                        bullets={wordpressContext.getMajorNamesByPathway(
                            pathway.term_id
                        )}
                        link={'/pathway/' + pathway.term_id}
                        prevURLs={prevURLs.concat([cluster.name])}
                    />
                ))}
            </ul>
        </motion.div>
    ) : (
        <Navigate to="/" />
    )
}

export default Cluster
