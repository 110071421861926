import React, {useContext, useEffect, useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import Index from "../../pages";
import Search from "../../pages/Search";
import Cluster from "../../pages/Cluster";
import Pathway from "../../pages/Pathway";
import Major from "../../pages/Major";
import Course from "../../pages/Course";
import Welcome from "../../pages/welcome";
import {AnimatePresence} from 'framer-motion';
import {WordpressContext} from "../../context/Wordpress";

function AnimatedRoutes(){
    const location = useLocation()
    const [isLoaded, setIsLoaded] = useState(false)
    const wordpressContext = useContext(WordpressContext)
    useEffect(() => {
        async function getWpData() {
            const wpRequest = await fetch(process.env.REACT_APP_WP_BASE)
            const requiredData = await wpRequest.json()
            wordpressContext.storeInfo(requiredData)
            setIsLoaded(true)
        }
        getWpData()
    }, [])

    return (
        <AnimatePresence exitBeforeEnter={true}>
            {isLoaded && (
        <Routes location={location} key={location.pathname}>
            <Route path="/role" element={<Index />} />
            <Route path="/search" element={<Search />} />
            <Route path="/cluster/:id" element={<Cluster />} />
            <Route path="/pathway/:id" element={<Pathway />} />
            <Route path="/major/:id" element={<Major />} />
            <Route path="/course/:id" element={<Course />} />
            <Route path="/" element={<Welcome />} />
        </Routes>
                )}
        </AnimatePresence>
    )
}

export default AnimatedRoutes