import { Link, useNavigate } from 'react-router-dom'
import arrow from '../../assets/images/pageNavArrow.svg'
import '../../assets/css/ClusterNav.css'

export default function Footer(props) {
    const navigate = useNavigate()

    return (
        <div className="ClusterNav">
            {props.prevURLs.map((url, index) => (
                <>
                    <a
                        onClick={() =>
                            navigate(index - props.prevURLs.length + 1)
                        }
                    >
                        {url}
                    </a>
                    {index != props.prevURLs.length - 1 && (
                        <img src={arrow} alt="to" />
                    )}
                </>
            ))}
        </div>
    )
}
