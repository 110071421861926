import React from 'react'

import '../../assets/css/ClusterCard.css'

export default function ClusterCard(props) {
    return (
        <div className="cluster-card">
            <div className="crop">
                <div className="content">
                    <div className="text">
                        <h3>{props.name}</h3>
                    </div>
                    <div className="sprite">
                        <img src={props.image} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}
