import { useNavigate } from 'react-router-dom'
import '../../assets/css/Header.css'
import SpriteCounselor from '../../assets/images/spr_counselor.svg'
import Back from '../../assets/images/back.png'
import OccupationCheck from './OccupationCheck'
import React from "react";

function CreditCheck(props){
    return props.credits === undefined || props.credits.length === 0 ? (
        <>
        </>
    ) : (
        <p className={"about-program-text"}>Credits Required: {props.credits}</p>
    )
}

function DepartmentCheck(props){
    return props.program === undefined || props.program.length === 0 ? (
        <>
        </>
    ) : (
        <p className={"about-program-text"}>Department: {props.program}</p>
    )

}


export default function Header(props) {
    const navigate = useNavigate()

    return (
        <div className="Header">
            <div onClick={() => navigate(-1)} className="back">
                <button className="">
                    <img src={Back} />
                </button>
                <p>BACK</p>
            </div>
            <div className="text">
                <div className={"text-box"}>
                <h2 className='header-type' dangerouslySetInnerHTML={{__html: props.type}}/>
                <h1 dangerouslySetInnerHTML={{__html: props.title}}/>
                {/*<p className={props.type === 'CAREER CLUSTER' ? 'shrink' : ''}*/}
                <p className={"major-header-text"}
                dangerouslySetInnerHTML={{__html: props.description}}>
                </p>
                {props.type === 'Major' && (
                    <>
                        <div className={"about-program"}>
                            <h3>About the Program</h3>
                            <DepartmentCheck program={props.program}/>
                            <CreditCheck credits={props.credits}/>
                        </div>
                        <div className={"occupations"}>
                            <OccupationCheck occupations={props.occupations} />
                        </div>
                    </>
                )}
            </div>
                {props.type === 'Career Cluster' && (
                    <img className={'cluster-sprite'} src={props.image} alt="" />
                )}
            </div>
        </div>
    )
}
