import React from "react";

function SalaryCheck(props){
    let internationalNumberFormat = new Intl.NumberFormat('en-US')
    return props.salary !== undefined || props.salary !== '' ? (
        <span>
            Salary: $
            {internationalNumberFormat.format(props.salary)}
        </span>
    ) : (
        <></>
    )
}


function OccupationCheck(props) {
    return props.occupations === undefined || props.occupations.length === 0 ? (
        <>
        </>
    ) : (
        <div>
            <h3>Possible Occupations</h3>
            <ul>
                {props.occupations
                    .filter((occupation) => occupation.occ_name !== '')
                    .map((occupation) => (
                        <li key={occupation.name}>
                            <p className="occupation">
                                {occupation.occ_name}
                                <SalaryCheck salary={occupation.salary}/>
                            </p>
                        </li>
                    ))}
            </ul>
        </div>
    )
}

export default OccupationCheck

