import React from 'react'
import { Link } from 'react-router-dom'

function CourseItem(props) {
    const { ID, post_title, course_name } = props.course
    return (
        <div key={props.key}>
            <Link to={'/course/' + ID}>
                <p><b><i>{post_title}</i></b> {course_name}</p>
            </Link>
        </div>
    )
}

export default CourseItem
