import React from 'react'
import { Link } from 'react-router-dom'
import '../assets/css/Welcome.css'
import people from '../assets/images/people.svg'
import desktopAnimation from '../assets/videos/desktop_CV.mp4'
import mobileAnimation from '../assets/videos/mobile_CV.mp4'
import {motion} from "framer-motion";
import Logo from '../assets/images/BSU-logo-horiz-RGB 1.svg';
import ReactGA from "react-ga4";


export default function welcome() {



    ReactGA.send({ hitType: "pageview", page: "/" });
    return (
        <motion.div className={"welcome"} initial={{opacity:0, x:100}} animate={{opacity:1, x:0}} exit={{opacity:0, x: -100}} transition={{duration: 0.3}} >
            <div className="information">
                <div className="head-text-wrap">
                    <p>Welcome to Ball State</p>
                    <h3>DUALCREDIT SITE</h3>
                </div>
                <br />
                <div className="text-wrap">
                    <section>
                        <h2>DUAL CREDIT</h2>
                        <p>
                            Explore courses that count for high school and college credit.
                        </p>
                    </section>
                    <section>
                        <h2>CLUSTERS</h2>
                        <p>
                            Career clusters can help students explore careers, majors, and courses relevant to their career goals.
                        </p>
                    </section>
                    <section>
                        <h2>PATHWAYS</h2>
                        <p>
                            Career pathways can help students explore majors and courses relevant to a specific career.
                        </p>
                    </section>
                </div>
                {/*<p style={{ textAlign: 'center' }}>Loading...</p>*/}
                <div className="img-wrap">
                    <video className={"desktop-animation"} src={desktopAnimation} alt="" autoPlay loop muted playsInline/>
                    <video className={"mobile-animation"} src={mobileAnimation} autoPlay loop muted playsInline/>
                </div>
            </div>

            <span>
                <button>
                    <Link to={'/role'}>Get Started</Link>
                </button>
            </span>

        </motion.div>
    )
}
