import React from 'react'
import logo from '../../assets/images/BSU-logo-footer.svg'
import {useLocation} from "react-router-dom";
import BSULogo from '../../assets/images/BSU-logo-horiz-RGB 1.svg'


export default function Footer() {
    let location = useLocation();
    let excludedPaths = ['/search', '/role', '/']
    if(excludedPaths.includes(location.pathname)){
        if(location.pathname === '/role'){
            return (
                <footer className={'no-background-footer'}>
                    <img className={'bsu-logo'} src={BSULogo}/>
                </footer>
            )
        }
        return (
            <footer>
            <img className={'bsu-logo'} src={BSULogo}/>
            </footer>
        )
    }

    return (
        <footer>
            <img className={'logo'} src={logo} />
            <p className={'disclaimer'}>
                Transferability is not guaranteed to be 100% accurate, please
                contact your university for confirmation before registering.{' '}
            </p>
        </footer>
    )
}
