import { Link, useLocation } from 'react-router-dom'
import '../../assets/css/WhiteCard.css'
import redArrow from '../../assets/images/redArrow.svg'

export default function WhiteCard(props) {
    return (
        <Link to={props.link} state={props.prevURLs}>
        <li key={props.title} className="WhiteCard">
            <h3 dangerouslySetInnerHTML={{__html: props.title}}/>
            {props.bullets && (
                <ul>
                    {props.bullets.map((bullet) => (
                        <li key={bullet} className="WhiteCardBulletList">{bullet}</li>
                    ))}
                </ul>
            )}
            {props.description && (
            <div className="description-box">
                <p className="description" dangerouslySetInnerHTML={{__html: props.description}}/>
            </div>
            )}
            {props.link && (
                <Link
                    className="see-more"
                    to={props.link}
                    state={props.prevURLs}
                >
                    <p className="see-more-with-arrow">See more {<img src={redArrow}/>}</p>
                </Link>
            )}
        </li>
        </Link>
    )
}
