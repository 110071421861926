import React from 'react'
import '../../assets/css/CourseCatalogButton.css'

export default function CourseCatalogButton(props) {
    return (
        <div className={'course-catalog-button-div'}>
        <a className={'course-catalog-button'} href={props.link} target="_blank">
            Visit Course Catalog
        </a>
        </div>
    )
}
