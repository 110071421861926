import React from 'react'
import {useLocation, useMatch} from "react-router-dom";
import { useMediaQuery } from 'react-responsive'

export default function Header() {
    let location = useLocation()
    let excludedPathsDesktop = ['/']
    let includedPathsMobile = ['/home']
    const isDesktop = useMediaQuery({
        query: '(min-width: 500px)'
    })

    if(isDesktop && excludedPathsDesktop.includes(location.pathname)){
        return null
    }
    if(!isDesktop && !includedPathsMobile.includes(location.pathname)){
        return null
    }

    return (
        <header>
            <div className="container">
                <h1></h1>
            </div>
        </header>
    )
}
