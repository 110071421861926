import React from "react";
import CourseList from "./CourseList";

function MajorClassCheck(props){
    return props.courses === undefined || props.courses.length === 0 ? (
        <>
        </>
    ) : (
        <div>
            <h3 className="classHeader">Major Classes</h3>
            <CourseList
                prevURLs={props.prevURLs.concat([props.major.post_title])}
                classes={props.courses}
            />
        </div>
    )
}

export default MajorClassCheck