import '../App.css'
import { Link } from 'react-router-dom'
import CallingCard from '../components/functions/CallingCard'
import {motion} from "framer-motion";
import React from "react";
import BrowseDualCreditLink from "../components/functions/BrowseDualCreditLink";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";
import clickEvent from "../components/functions/RolePageClickEvent";

function index() {
    let hasScrolled = false;
    const scrollEvent = () => {
        if(!hasScrolled){
            ReactGA.event({
                category: "Home Browse",
                action: "Swipe",
                label: "Role Swipe",
                transport: "xhr",
            });
            hasScrolled=true
        }
        const mobLinks = document.querySelector('#mobLinks')
        const studentDot = document.querySelector('#student-dot')
        const counselorDot = document.querySelector('#counselor-dot')
        const parentDot = document.querySelector('#parent-dot')
        if(mobLinks.scrollLeft < (window.outerWidth*2)/3){
            studentDot.className = 'active'
            counselorDot.className = ''
            parentDot.className = ''
        }
        if(mobLinks.scrollLeft>(window.outerWidth*2)/3 && mobLinks.scrollLeft<(window.outerWidth*2)*(2/3)){
            studentDot.className = ''
            counselorDot.className = 'active'
            parentDot.className = ''
        }
        if(mobLinks.scrollLeft>(window.outerWidth*2)*(2/3)){
            studentDot.className = ''
            counselorDot.className = ''
            parentDot.className = 'active'
        }
    }

    ReactGA.send({ hitType: "pageview", page: "/role" });

    return (
        <motion.div className="App Role" initial={{opacity:0, x:100}} animate={{opacity:1, x:0}} exit={{opacity:0, x: -100}} transition={{duration: 0.3}}>
            <Helmet>
            <title>Choose a Role from Ball State University</title>
            </Helmet>
            <div className="mobile-header"></div>
            <div className="background" id={'background'}>
                <nav>
                    <div className="mob-links" id='mobLinks' onScroll={scrollEvent}>
                        <Link to="/search" id='student'>
                            <CallingCard id={2} onClick={e => {
                                clickEvent({role: 'student'})
                            }}/>
                        </Link>
                        <Link to="/search" id='counselor'>
                            <CallingCard id={1} onClick={e => {
                                clickEvent({role: 'counselor'})
                            }}/>
                        </Link>
                        <Link to="/search" id='parent'>
                            <CallingCard id={3} onClick={e => {
                                clickEvent({role: 'parent'})
                            }}/>
                        </Link>
                    </div>
                    <ol className='mobile-dots' id='paging'>
                        <li className='active' id='student-dot'></li>
                        <li id='counselor-dot'></li>
                        <li id='parent-dot'></li>
                    </ol>
                </nav>
                <nav>
                    <div className="links">
                        <div>
                        <Link to="/search">
                            <CallingCard id={2} onClick={e => {
                                clickEvent({role: 'student'})
                            }}/>
                        </Link>
                        </div>
                        <div>
                        <Link to="/search">
                            <CallingCard id={1} onClick={e => {
                                clickEvent({role: 'counselor'})
                            }}/>
                        </Link>
                        </div>
                        <div>
                        <Link to="/search">
                            <CallingCard id={3} onClick={e => {
                                clickEvent({role: 'parent'})
                            }}/>
                        </Link>
                        </div>
                    </div>
                </nav>
            </div>

        </motion.div>
    )
}

export default index
